<template>
  <validation-observer
    ref="examinationRoomSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="examinationRoomSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm phòng thi theo học phần' : 'Cập nhật phòng thi theo học phần'"
      @show="onShow"
      @hide="onHide"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label-for="subject_id">
          <template v-slot:label>
            Học phần <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Học phần"
            rules="required"
          >
            <v-select
              v-model="targetExaminationRoom.subject_id"
              :options="subjectOptions"
              :reduce="option => option.value"
              disabled
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên phòng thi <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên phòng thi"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetExaminationRoom.name"
              name="name"
              placeholder="Nhập tên phòng thi theo học phần"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="room_id">
          <template v-slot:label>
            Phòng thi <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Phòng thi"
            rules="required"
          >
            <v-select
              v-model="targetExaminationRoom.room_id"
              :options="roomOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Thời gian bắt đầu"
          label-for="start_at"
        >
          <flat-pickr
            v-model="targetExaminationRoom.start_at"
            :config="config"
            class="form-control"
            placeholder="Thời gian bắt đầu"
            name="start_at"
          />
        </b-form-group>

        <b-form-group
          label="Thời gian kết thúc"
          label-for="start_at"
        >
          <flat-pickr
            v-model="targetExaminationRoom.end_at"
            :config="config"
            :disabled="!targetExaminationRoom.start_at"
            class="form-control"
            placeholder="Thời gian kết thúc"
            :min-date="targetExaminationRoom.start_at"
            name="end_at"
            @on-change="valTime"
          />
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetExaminationRoom.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('examinationRoomSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import { vn } from 'flatpickr/dist/l10n/vn.js'
import { EXAMINATION_STATUS } from '@/const/status'

export default {
  name: 'ExaminationRoomSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    examinationRoom: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetExaminationRoom: {
        name: '',
        code: '',
        subject_id: null,
        room_id: null,
        status: 1,
        orderNo: 1,
        start_at: null,
        end_at: null,
      },
      config: {
        wrap: true,
        enableTime: true,
        altInput: true,
        altFormat: 'Y-m-d H:i',
        dateFormat: 'Y-m-d H:i K',
        locale: vn,
        time_24hr: true,
        // minDate: 'today',
      },
      statusOptions: EXAMINATION_STATUS,
      required,
    }
  },
  computed: {
    ...mapGetters({
      allRoom: 'examinationRoom/allRoom',
      allSubject: 'examinationRoom/allSubject',
    }),
    isCreated() {
      return !this.examinationRoom
    },
    roomOptions() {
      return this.allRoom.map(item => ({ value: item.id, label: item.name }))
    },
    subjectOptions() {
      return this.allSubject.map(item => ({ value: item.id, label: item.name }))
    },
  },
  methods: {
    ...mapActions({
      createExaminationRoom: 'examinationRoom/createExaminationRoom',
      updateExaminationRoom: 'examinationRoom/updateExaminationRoom',
      getAllRooms: 'examinationRoom/getAllRooms',
      getAllSubjects: 'examinationRoom/getAllSubjects',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getAllRooms(),
          this.getAllSubjects(),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      if (this.examinationRoom) {
        this.targetExaminationRoom = { ...this.examinationRoom }
      }
    },
    onHide() {
      this.$refs
        .examinationRoomSaveFormRef
        .reset()
      this.targetExaminationRoom = {
        name: '',
        code: '',
        subject_id: null,
        room_id: null,
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .examinationRoomSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createExaminationRoom(this.targetExaminationRoom)
            : await this.updateExaminationRoom(this.targetExaminationRoom)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('examinationRoomSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    valTime(selectedDates, dateStr, instance) {
      if (dateStr <= this.targetExaminationRoom.start_at) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thời gian kết thúc phải lớn hơn bắt đầu!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
