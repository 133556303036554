<template>
  <div>
    <b-modal
      id="examinationRoomTeacherModal"
      centered
      title="Danh sách giảng viên coi thi"
      size="lg"
      body-class="position-static"
      hide-footer
      @shown="onShown"
      :no-close-on-backdrop="true"
    >
      <div class="m-2">
        <b-row v-if="creatable">
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="$bvModal.show('examinationRoomTeacherSave')"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="PlusIcon" /> Thêm giám thị
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action'">
                  <b-button
                    v-if="deletable"
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click="onDelete(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị 1 đến </span>
                    <b-form-select
                      v-model="itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('conductCriteriaModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>

    <ExaminationRoomTeacherSave
      :examination-room-id="examinationRoomId"
      :subject-id="subjectId"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCol, BFormSelect, BModal, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExaminationRoomTeacherSave
from '@/views/examination-room/examination-room-teacher/ExaminationRoomTeacherSave.vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'ExaminationRoomTeacher',
  components: {
    ExaminationRoomTeacherSave,
    BOverlay,
    BPagination,
    BRow,
    BButton,
    BCol,
    BFormSelect,
    BModal,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    examinationRoomId: {
      type: Number,
      default: null,
    },
    subjectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Giám thị',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tổ bộ môn',
          field: 'team',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khoa/Bộ môn',
          field: 'departmentName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'examinationRoomTeacher/examinationRoomTeachers',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.EXAMINATION_ROOM_TEACHER)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.EXAMINATION_ROOM_TEACHER)
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  methods: {
    ...mapActions({
      getExaminationRoomTeachers: 'examinationRoomTeacher/getExaminationRoomTeachers',
      deleteExaminationRoomTeacher: 'examinationRoomTeacher/deleteExaminationRoomTeacher',
    }),
    async onShown() {
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getExaminationRoomTeachers({ examinationRoomId: this.examinationRoomId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onDelete(id) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa giám thị?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.deleteExaminationRoomTeacher(id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  await this.getExaminationRoomTeachers({ examinationRoomId: this.examinationRoomId })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
